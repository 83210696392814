import { render, staticRenderFns } from "./pricing.vue?vue&type=template&id=57cd0a00&scoped=true"
import script from "./pricing.vue?vue&type=script&lang=js"
export * from "./pricing.vue?vue&type=script&lang=js"
import style0 from "./pricing.styl?vue&type=style&index=0&id=57cd0a00&prod&scoped=true&lang=stylus&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57cd0a00",
  null
  
)

export default component.exports